import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Container, Row, Col } from "react-bootstrap"
import danceofsouls from "../images/dance-of-souls.jpg"
import monsterinlove from "../images/monsterinlove.jpg"
import bigbad from "../images/big-bad.jpg"
import fistfull from "../images/fist-full.jpg"
import cobra from "../images/cobra.jpg"
import wildside from "../images/wild-side.jpg"

const ReleasesPage = () => (
  <Layout pageInfo={{ pageName: "releases" }}>
    
    <Container className="mt-5">
        <SEO title="Releases" />
        <h1>Releases from the Dirteez</h1>
        <p>Below are a list of all the records we have ever produced. We only have the last three for sale.</p>
        <div className="release mt-5">
            <Row>
                <Col className="col-12 col-md-6">
                    <img className="img-fluid" src={monsterinlove} alt="Monster in Love" />
                </Col>
                <Col className="col-12 col-md-6">
                    <h3>Monster in Love</h3>
                    <p>Released in <strong>2023</strong><br />Includes a <strong>poster</strong> and a <strong>lyrics inlay</strong></p>
                    <ol>
                        <li>Last Station of Broken Hearts</li>
                        <li>Dead River</li>
                        <li>Devil Dance</li>
                        <li>Hungry</li>
                        <li>Monster in Love</li>
                        <li>Lonesome Dance</li>
                        <li>Happy Dogs</li>
                        <li>Space Cookie</li>
                        <li>Endless Night (Live)</li>
                        <li>Thirsty Road (Live)</li>
                        <li>Shy (Live)</li>
                    </ol>
                </Col>
            </Row>
        </div>
        <div className="release">
            <Row>
                <Col className="col-12 col-md-6">
                    <img className="img-fluid" src={danceofsouls} alt="Dance of Souls" />
                </Col>
                <Col className="col-12 col-md-6">
                    <h3>Dance of Souls</h3>
                    <p>Released in <strong>2019</strong></p>
                    <ol>
                        <li>Lazy</li>
                        <li>Dance of Souls</li>
                        <li>Shark Smile</li>
                        <li>Thirsty Road</li>
                        <li>Talisman</li>
                        <li>Strong</li>
                        <li>I Can't Wait</li>
                        <li>Boogie Rats</li>
                        <li>Keep It Dirty</li>
                        <li>Endless Night</li>
                    </ol>
                </Col>
            </Row>
        </div>
        <div className="release">
            <Row>
                <Col className="col-12 col-md-6">
                    <img className="img-fluid" src={bigbad} alt="Big Bad Rock n'Roll" />
                </Col>
                <Col className="col-12 col-md-6">
                    <h3>Big Bad Rock n'Roll</h3>
                    <p>Released in <strong>2016</strong></p>
                    <ol>
                        <li>Big Bad Rock n'Roll</li>
                        <li>Like a Bullet in my Head</li>
                        <li>Night of the Worm</li>
                        <li>Shy</li>
                    </ol>
                </Col>
            </Row>
        </div>
        <div className="release">
            <Row>
                <Col className="col-12 col-md-6">
                    <img className="img-fluid" src={fistfull} alt="A Fistful of Blue Spells" />
                </Col>
                <Col className="col-12 col-md-6">
                    <h3>A Fistful of Blue Spells</h3>
                    <p>Released in <strong>2006</strong></p>
                    <ol>
                        <li>The Real Potion n.9</li>
                        <li>Haunted Blues</li>
                        <li>Car Crash</li>
                        <li>Now We're Dead</li>
                        <li>Atom Boy</li>
                        <li>Rubber Romm</li>
                        <li>Paradise</li>
                        <li>Ghost Danse</li>
                        <li>Voodoo Woman</li>
                        <li>Not Alone</li>
                        <li>I Want Some of That</li>
                        <li>Dirty Talk With Batman</li>

                    </ol>
                </Col>
            </Row>
        </div>
        <div className="release">
            <Row>
                <Col className="col-12 col-md-6">
                    <img className="img-fluid" src={cobra} alt="The Curse of the Haunted Cobra" />
                </Col>
                <Col className="col-12 col-md-6">
                    <h3>The Curse of the Haunted Cobra</h3>
                    <p>Released in <strong>2005</strong></p>
                    <ol>

                        <li>Werewolf</li>
                        <li>His song</li>
                        <li>Pink bikini</li>
                        <li>Waiting</li>
                        <li>The Ring</li>
                        <li>Question in wine</li>
                        <li>Nocturne Cobras</li>
                        <li>Don't really care</li>
                        <li>Summertime</li>
                        <li>Lizard Man</li>
                        <li>The Curse</li>

                    </ol>
                </Col>
            </Row>
        </div>
        
        <div className="release">
            <Row>
                <Col className="col-12 col-md-6">
                    <img className="img-fluid" src={wildside} alt="The Wild Side of Love" />
                </Col>
                <Col className="col-12 col-md-6">
                    <h3>The Wild Side of Love</h3>
                    <p>Released in <strong>1990</strong></p>
                    <ol>                    
                        <li>Peeping Tom	</li>
                        <li>Carry Me	</li>
                        <li>Death Race	</li>
                        <li>Law Of The ?	</li>
                        <li>Gipsy Rose Lee	</li>
                        <li>Cannibal Obsessions	</li>
                        <li>Raw Bones Party	</li>
                        <li>The Beat Inside	</li>
                        <li>Deadly Bop</li>

                    </ol>
                </Col>
            </Row>
        </div>
    </Container>
  </Layout>
)

export default ReleasesPage
